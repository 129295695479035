import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/PrayerRequestPage.css';

const PrayerRequestPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    location: '',
    phone: '',
    contact: '',
    subject: '',
    category: '',
    request: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_07c15as',
      'template_jb9m20i',
      formData,
      'qtYUZzZsCvkeDQCHh'
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Prayer request sent successfully!');
      document.querySelector('button[type="submit"]').classList.add('submitted');
    })
    .catch((err) => {
      console.error('FAILED...', err);
      alert('Failed to send prayer request.');
    });
  };

  return (
    <div className="prayer-request-page">
      <h1>Prayer Requests</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="(Optional)"
          />
        </div>
        <div>
          <label htmlFor="email">Email*:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="location">City, State, Country:</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            placeholder="(Optional)"
          />
        </div>
        <div>
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="(Optional)"
          />
        </div>
        <div>
          <label>Do You Want Us To Reach Out Regarding Your Prayer?</label>
          <div className="radio-group">
            <input type="radio" id="yes" name="contact" value="yes" onChange={handleChange} />
            <label htmlFor="yes">Yes</label>
            <input type="radio" id="no" name="contact" value="no" onChange={handleChange} />
            <label htmlFor="no">No</label>
          </div>
        </div>
        <div>
          <label htmlFor="subject">Subject*:</label>
          <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="category">Category*:</label>
          <select id="category" name="category" value={formData.category} onChange={handleChange} required>
            <option value="">Select a category</option>
            <option value="general">General Prayer</option>
            <option value="addiction">Addiction</option>
            <option value="emotional">Emotional Support</option>
            <option value="finances">Finances</option>
            <option value="family">Family</option>
            <option value="forgiveness">Forgiveness</option>
            <option value="guidance">Guidance</option>
            <option value="health">Health</option>
            <option value="relationship">Relationship Issues</option>
            <option value="miscellaneous">Miscellaneous</option>
            <option value="peace">Peace</option>
            <option value="purpose">Purpose</option>
            <option value="salvation-loved-one">Salvation For a Loved One</option>
            <option value="salvation-self">Salvation For Myself</option>
            <option value="wisdom">Wisdom</option>
            <option value="not-say">I'd Rather Not Say</option>
          </select>
        </div>
        <div>
          <label htmlFor="request">Prayer Request*:</label>
          <textarea id="request" name="request" value={formData.request} onChange={handleChange} required></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PrayerRequestPage;
