import React from 'react';
import '../styles/HomeSermonBanner.css';

const HomeSermonBanner = ({ 
  title, 
  preacher, 
  date, 
  backgroundImage, 
  alignmentGif, 
  verses 
}) => {
  return (
    <div className="home-sermon-banner">
      <div className="banner-content">
        <img 
          src={backgroundImage} 
          alt={title} 
          className="static-image"
        />
        <img 
          src={alignmentGif} 
          alt={title} 
          className="hover-gif"
        />
        <div className="banner-overlay">
          <span className="latest-label">Latest Sermon</span>
          <h2>{title}</h2>
          <p className="sermon-details">Key Verses: {verses} | {preacher}</p>
          <p className="sermon-date">{date}</p>
          <div className="banner-buttons">
            <a 
              href="https://www.youtube.com/live/pnjueNvSwK0?si=Jf1b2qc7_2SZFtAS&t=6322"
              className="watch-button" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Watch Sermon
            </a>
            <a 
              href="https://www.youtube.com/@MOUNTCARMELPC/streams" 
              className="all-sermons-button" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              View All Sermons
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSermonBanner; 