import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import SermonsPage from './pages/SermonsPage';
import TithesOfferingPage from './pages/TithesOfferingPage';
import EventsPage from './pages/EventsPage';
import ShopPage from './pages/ShopPage';
import PrayerRequestPage from './pages/PrayerRequestPage';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import EventDetails from './components/EventDetails';

function App() {
  const dotOverlayRef = useRef(null);

  useEffect(() => {
    const dotOverlay = dotOverlayRef.current;
    if (!dotOverlay) return;

    const handleMouseMove = (e) => {
      const rect = dotOverlay.getBoundingClientRect();
      const x = 1 - (e.clientX - rect.left) / rect.width;
      const y = 1 - (e.clientY - rect.top) / rect.height;

      dotOverlay.style.setProperty('--mouse-x', x);
      dotOverlay.style.setProperty('--mouse-y', y);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Router>
      <div className="App relative min-h-screen">
        <div id="dot-overlay" ref={dotOverlayRef} className="absolute inset-0 pointer-events-none z-0"></div>
        <div className="relative z-10 flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/sermons" element={<SermonsPage />} />
              <Route path="/tithes-offering" element={<TithesOfferingPage />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/shop" element={<ShopPage />} />
              <Route path="/events/:id" element={<EventDetails />} />
              <Route path="/prayer-request" element={<PrayerRequestPage />} />
            </Routes>
          </main>
          <Footer />
          <Analytics />
          <SpeedInsights />
        </div>
      </div>
    </Router>
  );
}

export default App;
