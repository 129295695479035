import React from 'react';
import { Link } from 'react-router-dom';
import { eventsData } from '../data/eventsData';
import '../styles/HomePageEventCards.css';

// Import all images from the EventCards directory
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const EventImages = importAll(require.context('../assets/EventCards', false, /\.(png|jpe?g|svg)$/));

const HomePageEventCards = () => {
  const currentDate = new Date();
  console.log('Current date:', currentDate);
  
  const upcomingEvents = eventsData
    .map(event => {
      // Use the last day of the date range for sorting
      const lastDay = event.eventDateRange?.days[event.eventDateRange.days.length - 1] || 1;
      const month = event.eventDateRange?.month || event.month;
      const year = event.year || currentDate.getFullYear();
      const dateString = `${month} ${lastDay} ${year} ${event.time}`;
      return {
        ...event,
        dateObject: new Date(dateString)
      };
    })
    .filter(event => event.dateObject > currentDate)
    .sort((a, b) => a.dateObject.getTime() - b.dateObject.getTime())
    .slice(0, 3);

  console.log('Upcoming events:', upcomingEvents);
  console.log('Available images:', EventImages);

  return (
    <div className="home-page-event-cards">
      {upcomingEvents.length === 0 ? (
        <div>No upcoming events</div>
      ) : (
        upcomingEvents.map((event, index) => (
          <Link to={`/events/${event.id}`} key={index} className="home-page-event-card">
            <div className="event-title">{event.title}</div>
            {event.image && EventImages[event.image] ? (
              <img 
                src={EventImages[event.image]}
                alt={event.title} 
                className="event-image"
              />
            ) : (
              <div className="placeholder-image">{event.title}</div>
            )}
          </Link>
        ))
      )}
    </div>
  );
};

export default HomePageEventCards;
