import React from 'react';
import '../styles/ShopPage.css';

const ShopPage = () => {
  return (
    <div className="shop-page">
      <div className="content-wrapper">
        <h1>Shop</h1>
        <div className="shop-container">
          <p>Restocking our shop with new items...</p>
          <p>Check back soon!</p>
          {/* Add more shop content here */}
        </div>
      </div>
    </div>
  );
};

export default ShopPage;
