import React from 'react';
import heartHandsImage from '../assets/GivingAssets/heart-hands.svg';
import '../styles/TithesOfferingPage.css';
import scriptLogo from '../assets/icons/script-logo.svg';

const TithesOfferingPage = () => {
  const handlePaypalClick = () => {
    window.open("https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=8XD5WLUBAX9SG&source=url&ssrt=1707857469879", "_blank", "noopener,noreferrer");
  };

  const handleGivelifyClick = () => {
    window.open("https://www.givelify.com/donate/mt-carmel-pentecostal-church-bronx-ny-2j7wy5ODUyOQ==/donation/amount", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="tithes-offering-page">
      <div className="left-section" style={{ backgroundImage: `url(${heartHandsImage})` }}>
        <div className="overlay">
          <h1>Behold the miracles that unfold when your generosity meets God's purpose.</h1>
        </div>
        <img src={scriptLogo} alt="Script Logo" className="script-logo" />
      </div>
      <div className="right-section">
        <div className="centered-content">
          <p>Give using a credit or debit card with Paypal, Givelify, or send in a gift via P.O. Box. Give a single gift, or schedule recurring giving.</p>
          <h4>Choose how you want to give</h4>
          <div className="payment-options">
            <button onClick={handlePaypalClick}>Paypal</button>
            <button onClick={handleGivelifyClick}>Givelify</button>
            <button>P.O. Box</button>
          </div>
          <div className="additional-links">
            <a href="#" className="faq-link">View the FAQs</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TithesOfferingPage;
