import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import '../styles/EventsPage.css';
import { FaFilter } from 'react-icons/fa'; // Import the filter icon

// Assuming you have an events data source, import it here
import { eventsData } from '../data/eventsData'; // Adjust the import path as needed

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const EventImages = importAll(require.context('../assets/EventCards', false, /\.(png|jpe?g|svg)$/));

console.log("Event Images:", EventImages);

const EventsPage = () => {
  const [events, setEvents] = useState(() => {
    const processedEvents = eventsData.map(event => ({
      ...event,
      image: EventImages[event.image] || ''
    }));
    console.log("Processed events:", processedEvents);
    return processedEvents;
  });

  const [filters, setFilters] = useState({
    category: [],
    month: [],
    inPerson: false,
    online: false,
  });
  const [currentTime, setCurrentTime] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  const filterCurrentEvents = useCallback((eventsToFilter, now) => {
    const currentYear = now.getFullYear();
    console.log("Current date and time:", now.toLocaleString());

    return eventsToFilter.filter(event => {
      const lastDay = event.eventDateRange.days[event.eventDateRange.days.length - 1];
      const [time, period] = event.time.split(' ');
      const [hours, minutes] = time.split(':');

      let eventDate = new Date(
        currentYear, 
        getMonthNumber(event.eventDateRange.month), 
        lastDay, 
        period === 'PM' ? (parseInt(hours) % 12) + 12 : parseInt(hours), 
        parseInt(minutes)
      );

      // If the event date is in the past, add a year
      if (eventDate < now) {
        return false; // Filter out old events
      }

      const isCurrentEvent = eventDate >= now;
      console.log(`Event: ${event.title}`);
      console.log(`  Original Date: ${event.date} ${event.time}`);
      console.log(`  Calculated Date: ${eventDate.toLocaleString()}`);
      console.log(`  Is current: ${isCurrentEvent}`);
      console.log(`  Comparison: ${eventDate.getTime()} >= ${now.getTime()}`);
      console.log('---');

      return isCurrentEvent;
    });
  }, []);

  const currentEvents = useMemo(() => {
    const filtered = filterCurrentEvents(events, currentTime);
    console.log("Current events after filtering:", filtered);
    return filtered;
  }, [events, currentTime, filterCurrentEvents]);

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: Array.isArray(prevFilters[filterType])
        ? prevFilters[filterType].includes(value)
          ? prevFilters[filterType].filter(item => item !== value)
          : [...prevFilters[filterType], value]
        : !prevFilters[filterType]
    }));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEvents = useMemo(() => {
    // First filter the events
    const filtered = currentEvents.filter(event => 
      (searchTerm === '' || event.title.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (filters.category.length === 0 || filters.category.includes(event.category)) &&
      (filters.month.length === 0 || filters.month.includes(event.month)) &&
      (!filters.inPerson || event.inPerson) &&
      (!filters.online || event.online)
    );

    // Then sort the filtered events chronologically
    return filtered.sort((a, b) => {
      // Convert dates to comparable format
      const monthOrder = {
        'January': 1, 'February': 2, 'March': 3, 'April': 4,
        'May': 5, 'June': 6, 'July': 7, 'August': 8,
        'September': 9, 'October': 10, 'November': 11, 'December': 12
      };

      // Compare years first
      if (a.year !== b.year) {
        return a.year - b.year;
      }

      // If same year, compare months
      if (a.month !== b.month) {
        return monthOrder[a.month] - monthOrder[b.month];
      }

      // If same month, compare days
      const aDay = a.eventDateRange?.days[0] || 1;
      const bDay = b.eventDateRange?.days[0] || 1;
      if (aDay !== bDay) {
        return aDay - bDay;
      }

      // If same day, compare times
      const aTime = new Date(`2000/01/01 ${a.time}`).getTime();
      const bTime = new Date(`2000/01/01 ${b.time}`).getTime();
      return aTime - bTime;
    });
  }, [currentEvents, searchTerm, filters]);

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  // Add these console logs before the return statement
  console.log("Current time:", currentTime);
  console.log("All events:", events);
  console.log("Current events:", currentEvents);
  console.log("Filtered events:", filteredEvents);

  console.log("Final filtered events:", filteredEvents);

  return (
    <div className="events-page">
      <div className="dot-overlay"></div>
      <div className="content-wrapper">
        <h1>Events</h1>
        <div className="search-and-filter">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search events..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <button className="filter-toggle" onClick={toggleFilterVisibility}>
            <FaFilter />
          </button>
        </div>
        <div className="events-container">
          <div className={`filters-column ${isFilterVisible ? 'visible' : ''}`}>
            <div className="filters">
              <h2>Filter Events</h2>
              <div className="filter-section">
                <h3>Category</h3>
                {['Bible Study', 'Prayer Meeting', 'Worship Service', 'Youth Meeting', 'Fundraiser', 'Other'].map(category => (
                  <label key={category}>
                    <input
                      type="checkbox"
                      checked={filters.category.includes(category)}
                      onChange={() => handleFilterChange('category', category)}
                    />
                    {category}
                  </label>
                ))}
              </div>
              <div className="filter-section">
                <h3>Month</h3>
                {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(month => (
                  <label key={month}>
                    <input
                      type="checkbox"
                      checked={filters.month.includes(month)}
                      onChange={() => handleFilterChange('month', month)}
                    />
                    {month}
                  </label>
                ))}
              </div>
              <div className="filter-section">
                <h3>Event Type</h3>
                <label>
                  <input
                    type="checkbox"
                    checked={filters.inPerson}
                    onChange={() => handleFilterChange('inPerson')}
                  />
                  In Person
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={filters.online}
                    onChange={() => handleFilterChange('online')}
                  />
                  Online
                </label>
              </div>
            </div>
          </div>
          <div className="event-grid">
            {filteredEvents.length > 0 ? (
              filteredEvents.map(event => (
                <Link to={`/events/${event.id}`} key={event.id} className="event-card-link">
                  <div className="event-card">
                    <img src={event.image} alt={event.title} />
                    <div className="event-card-content">
                      <h3>{event.title}</h3>
                      <p>{event.category}</p>
                      <div className="event-details">
                        <span>{event.date}</span>
                        <span>{event.time}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p>No events to display</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsPage;
// Add this helper function outside of the component
function getMonthNumber(month) {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 
                  'July', 'August', 'September', 'October', 'November', 'December'];
  return months.indexOf(month);
}

